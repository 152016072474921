export const navItems = [
    {
        title: 'Transfer',
        disabled: false,
        path: '/',
    },
    {
        title: 'Stake',
        disabled: false,
        path: '/stake',
    },
    {
        title: 'Pool',
        disabled: true,
        path: '/pool',
    },
    {
        title: 'Convert',
        disabled: true,
        path: '/',
    },
];
