import { cn } from '@bem-react/classname';
import { FC, useCallback, useMemo } from 'react';
import { Icons } from 'assets';
import { NetworkType, Networks, Tokens } from 'types';
import { usePoolData } from 'pages/Pool/Pool.hooks';

import './PoolItem.scss';

const CnPoolItem = cn('poolItem');

export const PoolItem: FC<{
    token: Tokens;
    network: NetworkType;
    icon: any;
    networkIcon: any;
    poolClickHandler: (pool: any) => void;
}> = ({ token, network, icon, networkIcon, poolClickHandler }) => {
    const { volumes, apy } = usePoolData({ networkType: network, token });

    const curr7dVolume = useMemo(
        () =>
            volumes &&
            volumes.volumes7d.find(
                (volume: any) => token === volume.tokenName.toLowerCase(),
            ),
        [volumes, token],
    );

    const curr24hVolume = useMemo(
        () =>
            volumes &&
            volumes.volumes24hr.find(
                (volume: any) => token === volume.tokenName.toLowerCase(),
            ),
        [volumes, token],
    );

    const poolClickCallback = useCallback(() => {
        poolClickHandler({
            apy,
            token,
            icon,
            networkIcon,
        });
    }, [apy, token, icon, networkIcon, poolClickHandler]);

    return (
        <div onClick={poolClickCallback} className={CnPoolItem()}>
            <div className={CnPoolItem('head')}>
                <div className={CnPoolItem('item')}>
                    <div className={CnPoolItem('item-label')}>Token</div>
                    <div className={CnPoolItem('token')}>
                        {icon}
                        {token.toUpperCase()}
                    </div>
                </div>
                <div className={CnPoolItem('item')}>
                    <div className={CnPoolItem('item-label')}>Network</div>
                    <div className={CnPoolItem('network')}>
                        <div className={CnPoolItem('network-icon')}>
                            {networkIcon}
                        </div>
                        Aptos
                    </div>
                </div>
            </div>
            <div className={CnPoolItem('body')}>
                <div className={CnPoolItem('item')}>
                    <div className={CnPoolItem('item-label')}>Volume (24h)</div>
                    <div className={CnPoolItem('item-value')}>
                        ${Number(curr24hVolume?.volume ?? 0).toFixed(2)}
                    </div>
                </div>

                <div className={CnPoolItem('item')}>
                    <div className={CnPoolItem('item-label')}>Volume (7d)</div>
                    <div className={CnPoolItem('item-value')}>
                        ${Number(curr7dVolume?.volume ?? 0).toFixed(2)}
                    </div>
                </div>
            </div>
        </div>
    );
};
