import { jsonRpcProvider } from 'wagmi/providers/jsonRpc';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import { InjectedConnector } from 'wagmi/connectors/injected';
import { environment } from 'consts';
import { EvmChains } from 'types';
import {
    arbitrum,
    bsc,
    polygon,
    optimism,
    arbitrumGoerli,
    bscTestnet,
    polygonMumbai,
    optimismGoerli,
    zkSync,
    zkSyncTestnet,
    polygonZkEvmTestnet,
    polygonZkEvm,
    boba,
} from '@wagmi/chains';
import { bobaBnbMainnet, bobaBnbTestnet, bobaEthTestnet } from 'consts';
import BigNumber from 'bignumber.js';
import { configureChains, createClient } from 'wagmi';

const rpcFromChain = {
    [EvmChains.Arbitrum]: 'https://arb1.arbitrum.io/rpc',
    [EvmChains.Bsc]: 'https://bsc-dataseed1.binance.org',
    [EvmChains.Optimism]:
        'https://opt-mainnet.g.alchemy.com/v2/WU4jOHETnzAT3NGp75MNxCtGz1hzJ361',
    [EvmChains.Polygon]: 'https://polygon-rpc.com',
    [EvmChains.ZkSync]: 'https://mainnet.era.zksync.io',
    [EvmChains.ZkPolygon]: 'https://zkevm-rpc.com',
    [EvmChains.BobaEth]: 'https://mainnet.boba.network',
    [EvmChains.BobaBnb]: 'https://bnb.boba.network',
};

const rpcFromChainTestnet = {
    [EvmChains.Arbitrum]: 'https://goerli-rollup.arbitrum.io/rpc',
    [EvmChains.Bsc]: 'https://data-seed-prebsc-1-s1.binance.org:8545',
    [EvmChains.Optimism]: 'https://goerli.optimism.io',
    [EvmChains.Polygon]: 'https://rpc-mumbai.maticvigil.com',
    [EvmChains.ZkSync]: 'https://testnet.era.zksync.dev',
    [EvmChains.ZkPolygon]: 'https://rpc.public.zkevm-test.net',
    [EvmChains.BobaEth]: 'https://goerli.boba.network',
    [EvmChains.BobaBnb]: 'https://testnet.bnb.boba.network',
};

export const currRpcConfig =
    environment === 'dev' ? rpcFromChainTestnet : rpcFromChain;

export const currChainsConfig =
    environment === 'dev'
        ? [
              arbitrumGoerli,
              bscTestnet,
              polygonMumbai,
              optimismGoerli,
              polygonZkEvmTestnet,
              zkSyncTestnet,
              //   bobaEthTestnet,
              //   bobaBnbTestnet,
          ]
        : [
              arbitrum,
              bsc,
              polygon,
              optimism,
              polygonZkEvm,
              zkSync,
              //   boba,
              //   bobaBnbMainnet,
          ];

export const EVM_PROVIDER_POOL_INTERVAL = 15_000;

export const checkIsTokenAllowed = (num: any): boolean => {
    const allowance = BigNumber(num);
    const zero = BigNumber(0);

    return allowance.isGreaterThan(zero);
};

const { chains, provider, webSocketProvider } = configureChains(
    currChainsConfig as any,
    [
        jsonRpcProvider({
            rpc: (chain) => {
                if (chain.id === EvmChains.Bsc) {
                    return {
                        http: chain.rpcUrls.default.http[0],
                    };
                }

                return {
                    http: currRpcConfig[chain.id as EvmChains],
                };
            },
        }),
    ],
    { pollingInterval: EVM_PROVIDER_POOL_INTERVAL },
);

const metamaskConnector = new MetaMaskConnector({ chains });

// const walletConnectConnector = new WalletConnectConnector({
//     chains,
//     options: {
//         projectId: '21f570f279ab4f03f2b8865dca8ded41',
//         metadata: {
//             name: 'Mover',
//             description: 'The Aptos centric bridge',
//             url: 'https://app.mov3r.xyz',
//             icons: ['https://mov3r.xyz/apple-touch-icon.png'],
//         },
//         showQrModal: true,
//     },
// });

const okxConnector = new InjectedConnector({
    chains,
    options: {
        name: 'OKX Wallet',
        getProvider: () => {
            if ((window as any)?.okxwallet) {
                return (window as any)?.okxwallet;
            }
        },
    },
});

const trustWalletConnector = new InjectedConnector({
    chains,
    options: {
        name: 'Trust Wallet',
        getProvider: () => {
            if ((window as any)?.trustwallet) {
                return (window as any)?.trustwallet;
            }
        },
    },
});

const xdefiWalletConnector = new InjectedConnector({
    chains,
    options: {
        name: 'Xdefi Wallet',
        getProvider: () => {
            if ((window as any)?.xfi?.ethereum) {
                return (window as any)?.xfi?.ethereum;
            }
        },
    },
});

// const braveWalletConnector = new InjectedConnector({
//     chains,
//     options: {
//         name: 'Brave Wallet',
//         getProvider: () => {
//             if ((window as any)?.ethereum) {
//                 return (window as any)?.ethereum;
//             }
//         },
//     },
// });

export const evmClient = createClient({
    autoConnect: true,
    connectors: [
        metamaskConnector,
        okxConnector,
        trustWalletConnector,
        // xdefiWalletConnector,
    ],
    provider,
    webSocketProvider,
});
