import {
    createContext,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { IEvmWalletContext } from './EvmWalletProvider.types';
import { useAppDispatch, useAppSelector, useThrottle } from 'hooks';
import { useLocation, useNavigate } from 'react-router';
import {
    Connector,
    useAccount,
    useBalance,
    useConnect,
    useContractRead,
    useContractWrite,
    useDisconnect,
    useNetwork,
    useSwitchNetwork,
} from 'wagmi';
import {
    liquidityKeyFromNetwork,
    networkFromChain,
    stableCoinAbi,
    supportedEvmChains,
    supportedEvmNetworks,
} from 'consts';
import { config } from 'config';
import {
    AptosChains,
    EvmChains,
    Networks,
    Tokens,
    TransactionStatus,
} from 'types';
import {
    clearTransferForm,
    fetchTransferLiquidity,
    setBalance,
    setTransferFrom,
    transferStatusAddItem,
    transferStatusRemoveItem,
    transferStatusUpdateItem,
} from 'store';
import { fetchEvmFees, fetchLiquidity, fetchTransactionQueue } from 'api';
import { mixpanelEvents } from 'analytic';
import { aIsGreaterThanOrEqualToB, multipliedByDecimals } from 'utils';

var evmPoolInterval: null | NodeJS.Timer = null;
var evmBalanceInterval: null | NodeJS.Timer = null;

export const EvmWalletContext = createContext<IEvmWalletContext>({
    address: null,
    connectors: [],
    connect: null,
    connector: null,
    formattedAddress: '',
    connected: false,
    disconnect: null,
    approveToken: null,
    switchNetwork: null,
    chainId: null,
    transfer: null,
    poolBalance: null,
    fetchBalance: null,
    relayerFee: null,
    uniqueKey: '',
    isTokenApproved: null,
});

interface IEvmWalletProviderContextProps {
    children: React.ReactNode;
}

export const EvmWalletProviderContext: React.FC<
    IEvmWalletProviderContextProps
> = ({ children }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const account = useAccount();
    const { switchNetworkAsync } = useSwitchNetwork();
    const { chain } = useNetwork();
    const { connectAsync, connectors } = useConnect();
    const { disconnectAsync } = useDisconnect();
    const address = useMemo(() => account.address, [account.address]) as string;
    const chainId = useMemo(() => (chain ? chain.id : null), [chain]);
    const selectedToken = useAppSelector((store) => store.user.selectedToken);
    const from = useAppSelector((store) => store.transferForm.from);
    const to = useAppSelector((store) => store.transferForm.to);

    const connectorName = useMemo(
        () => (account.connector ? account.connector?.name : null),
        [account.connector],
    );

    const currConfig = useMemo(
        () =>
            supportedEvmNetworks.includes(from)
                ? config[from][selectedToken]
                : config.arbitrum[selectedToken],
        [selectedToken, from],
    );

    const uniqueKey = useMemo(
        () => `${chainId}${address}::${currConfig?.unique}`,
        [address, currConfig?.unique, chainId],
    );

    const [relayerFee, setRelayerFee] = useState<number | null>(null);

    useEffect(() => {
        if (
            chain?.id &&
            supportedEvmChains.includes(chain?.id) &&
            networkFromChain[chain?.id as EvmChains] !== from
        ) {
            dispatch(setTransferFrom(networkFromChain[chain?.id as EvmChains]));
        }
    }, [chain?.id]);

    const fetchEvmFeesCallback = useCallback(
        (selectedToken: Tokens, to: Networks) => {
            fetchEvmFees(selectedToken, to).then((response) => {
                setRelayerFee(response);
            });
        },
        [],
    );

    useEffect(() => {
        if (selectedToken) {
            if (supportedEvmNetworks.includes(to)) {
                fetchEvmFeesCallback(selectedToken, to);
            }
        }
    }, [selectedToken, to]);

    useEffect(() => {
        if (address && chainId && !supportedEvmChains.includes(chainId)) {
            navigate('?modal=changeEthereumNetwork');
        }
    }, [chainId, address, account.isConnected]);

    const formattedAddress = useMemo(() => {
        if (address) {
            return `${address.slice(0, 9)}...${address.slice(
                address.length - 4,
            )}`;
        }

        return '';
    }, [address]);

    const balance = useBalance({
        address: address as `0x${string}`,
        chainId: currConfig?.chain as number,
        token: (currConfig?.token as `0x${string}`) ?? undefined,
        enabled: false,
        onSuccess(data) {
            dispatch(
                setBalance({
                    token: uniqueKey,
                    value: data?.formatted as string,
                }),
            );
        },
        onError(err) {
            dispatch(
                setBalance({
                    token: uniqueKey,
                    value: '0.00',
                }),
            );
        },
    });

    useEffect(() => {
        if (evmBalanceInterval) {
            clearInterval(evmBalanceInterval);
        }

        evmBalanceInterval = setInterval(() => {
            balance.refetch();
        }, 30000);

        return () => {
            if (evmBalanceInterval) {
                clearInterval(evmBalanceInterval);
            }
        };
    }, []);

    const poolBalance = useCallback(() => {
        if (evmPoolInterval) {
            clearInterval(evmPoolInterval);
        }
        let iEth = 0;

        evmPoolInterval = setInterval(async () => {
            await balance.refetch().then((balance) => {
                if (balance.status === 'success') {
                    dispatch(
                        setBalance({
                            token: uniqueKey,
                            value: balance.data?.formatted as string,
                        }),
                    );
                }

                if (!balance.data) {
                    dispatch(
                        setBalance({
                            token: uniqueKey,
                            value: '0.00',
                        }),
                    );
                }
            });

            if (iEth === 15) {
                if (evmPoolInterval) {
                    clearInterval(evmPoolInterval);
                }
            }

            iEth = iEth + 1;
        }, 3000);
    }, [uniqueKey, balance, dispatch]);

    const balanceStore = useAppSelector((store) => store.user.balance);
    const evmBalance = useMemo(
        () => balanceStore[uniqueKey],
        [balanceStore, uniqueKey],
    );

    useEffect(() => {
        if (address) {
            balance.refetch();
        }
    }, [uniqueKey]);

    useEffect(() => {
        if (evmPoolInterval) {
            clearInterval(evmPoolInterval);
        }
    }, [evmBalance]);

    const fetchBalance = useCallback(async () => {
        await balance.refetch();
    }, [balance]);

    const debouncedFetchBlanace = useThrottle(fetchBalance, 2000);

    const switchNetwork = useCallback(
        async (chainId: number) => {
            try {
                if (switchNetworkAsync) {
                    await switchNetworkAsync(chainId);
                }
            } catch (err) {
                throw err;
            }
        },
        [switchNetworkAsync],
    );

    const connect = useCallback(
        async (connector: Connector) => {
            try {
                const walletName = connector.name;

                mixpanelEvents.connectWallet(walletName);

                const {
                    chain: { id: chainId },
                    account,
                } = await connectAsync({ connector });

                mixpanelEvents.setPeople({
                    evmAddress: account,
                    evmType: walletName,
                });

                const network =
                    networkFromChain[chainId as EvmChains | AptosChains];

                mixpanelEvents.connectWalletSuccess(walletName, network);

                if (!supportedEvmChains.includes(chainId)) {
                    setTimeout(() => {
                        navigate('?modal=changeEthereumNetwork');
                    }, 500);
                } else {
                    navigate(location.pathname);
                }
            } catch (err: any) {
                if (
                    err?.name &&
                    err?.name?.includes('ConnectorNotFoundError') &&
                    connector.name === 'MetaMask'
                ) {
                    window.open(
                        'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn',
                        '_blank',
                    );
                } else if (
                    err?.name &&
                    err?.name?.includes('ConnectorNotFoundError') &&
                    connector.name === 'OKX Wallet'
                ) {
                    window.open(
                        'https://chrome.google.com/webstore/detail/okx-wallet/mcohilncbfahbmgdjkbpemcciiolgcge',
                        '_blank',
                    );
                }

                throw err;
            }
        },
        [connectAsync, navigate, location],
    );

    const disconnect = useCallback(async () => {
        await disconnectAsync();
        navigate(location.pathname);
    }, [disconnectAsync, navigate, location]);

    // NEW CONTRACT READ

    const allowance = useContractRead({
        abi: stableCoinAbi?.token as any,
        address: currConfig?.token as `0x${string}`,
        functionName: 'allowance',
        args: [address, currConfig?.bridge],
        enabled: false,
        cacheOnBlock: true,
    });

    const fetchAllowance = useCallback(async () => {
        return await allowance.refetch().then(
            (response) =>
                response.data as unknown as {
                    _hex: string;
                    isBignumber: boolean;
                },
        );
    }, [allowance]);

    const checkIsTokenApproved = useCallback(
        async (amount: string) => {
            const currAllowance = await fetchAllowance();

            if (!currAllowance?._hex) {
                return false;
            }

            const amountToSend = multipliedByDecimals(
                amount,
                currConfig.decimals,
            );

            return aIsGreaterThanOrEqualToB(currAllowance._hex, amountToSend);
        },
        [fetchAllowance, currConfig],
    );

    const approveContract = useContractWrite({
        abi: stableCoinAbi?.token as any,
        address: currConfig?.token as `0x${string}`,
        mode: 'recklesslyUnprepared',
        functionName: 'approve',
    });

    const approveToken = useCallback(
        async (amount: string) => {
            try {
                if (approveContract.writeAsync) {
                    mixpanelEvents.approveToken({
                        tokenEnum: currConfig.symbol,
                        network: from,
                    });

                    const contractResult = await approveContract.writeAsync({
                        recklesslySetUnpreparedArgs: [
                            currConfig?.bridge,
                            '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',
                            { from: address },
                        ],
                    });

                    await contractResult.wait();

                    const isEvmTokenApproved = await checkIsTokenApproved(
                        amount,
                    );

                    if (!isEvmTokenApproved) {
                        const message = 'Your allowance is below the amount';

                        mixpanelEvents.approveTokenError({
                            tokenEnum: currConfig.symbol,
                            network: from,
                            walletType: connectorName as string,
                            error: message,
                        });

                        throw Error(message);
                    }

                    mixpanelEvents.approveTokenSuccess({
                        tokenEnum: currConfig.symbol,
                        network: from,
                        walletType: connectorName as string,
                        amount,
                    });
                }
            } catch (err: any) {
                if (err.code === 4001) {
                    throw Error('User rejected request');
                } else {
                    throw err;
                }
            }
        },
        [
            address,
            currConfig,
            from,
            connectorName,
            approveContract,
            checkIsTokenApproved,
        ],
    );

    // END NEW CONTRACT READ

    const transferContract = useContractWrite({
        address: currConfig.bridge as `0x${string}`,
        abi: stableCoinAbi?.contract,
        functionName: (currConfig as unknown as any)?.function,
        mode: 'recklesslyUnprepared',
    });

    const transfer = useCallback(
        async (
            from: Networks,
            to: Networks,
            amount: string,
            receiveAddress: string,
            chainId: string | number,
            fromChainId: string | number,
        ) => {
            let hash: string | null = null;

            const amountToSend = multipliedByDecimals(
                amount,
                currConfig.decimals,
            );

            mixpanelEvents.tokenSend({
                from,
                to,
                recipient: receiveAddress,
                amount,
                tokenEnum: currConfig.symbol,
            });

            try {
                const liquidityNetworkType = liquidityKeyFromNetwork[to];

                dispatch(
                    fetchTransferLiquidity({
                        chainId: chainId as number,
                        networkType: liquidityNetworkType,
                    }),
                );

                if (transferContract.writeAsync) {
                    const liquidity = await fetchLiquidity(
                        chainId as number,
                        liquidityNetworkType,
                    );

                    const token =
                        to === Networks.Sui ? 'moverUSD' : selectedToken;

                    const currTokenLiquidity = liquidity.tokens.find(
                        (item: any) =>
                            item.name.toLowerCase() === token.toLowerCase(),
                    )?.balance;

                    if (Number(amount) > currTokenLiquidity) {
                        throw Error('Do not enough liquidity');
                    }

                    const transaction = await transferContract.writeAsync({
                        recklesslySetUnpreparedArgs: [
                            receiveAddress,
                            amountToSend,
                            currConfig.token,
                            chainId,
                            { from: address },
                        ],
                    });

                    hash = transaction.hash;

                    const { timeWait } = await fetchTransactionQueue(
                        from,
                        fromChainId,
                        chainId,
                    );

                    dispatch(
                        transferStatusAddItem({
                            from,
                            fromHash: transaction?.hash,
                            fromTransactionStatus: TransactionStatus.NEW,
                            fromChainId: fromChainId,
                            to,
                            toChaindId: chainId,
                            amount,
                            token: currConfig?.symbol,
                            deadline:
                                new Date().getTime() + (timeWait + 15) * 1000,
                            timeWait: timeWait + 15,
                            createdAt: new Date().getTime(),
                        }),
                    );

                    navigate('?modal=transferStatus', {
                        state: { hash: transaction.hash },
                    });

                    await transaction.wait();

                    dispatch(clearTransferForm());

                    mixpanelEvents.tokenSendSuccess({
                        from,
                        to,
                        recipient: receiveAddress,
                        amount,
                        tokenEnum: currConfig.symbol,
                        expectedTime: `${timeWait + 15}`,
                    });

                    dispatch(
                        transferStatusUpdateItem({
                            hash: transaction?.hash,
                            payload: {
                                fromTransactionStatus:
                                    TransactionStatus.COMPLETE,
                            },
                        }),
                    );
                }
            } catch (err: any) {
                mixpanelEvents.tokenSendError('token.send.error.other', {
                    from,
                    to,
                    recipient: receiveAddress,
                    amount,
                    tokenEnum: currConfig.symbol,
                    error: JSON.stringify(err),
                });

                if (hash) {
                    dispatch(transferStatusRemoveItem(hash));
                }

                throw err;
            }
        },
        [
            address,
            currConfig,
            transferContract,
            dispatch,
            navigate,
            selectedToken,
        ],
    );

    return (
        <EvmWalletContext.Provider
            value={{
                address,
                connectors,
                connect,
                connector: account.connector as Connector<any, any, any>,
                formattedAddress,
                connected: account.isConnected,
                disconnect,
                approveToken,
                isTokenApproved: checkIsTokenApproved,
                switchNetwork,
                chainId,
                transfer,
                poolBalance,
                fetchBalance: debouncedFetchBlanace,
                relayerFee,
                uniqueKey,
            }}
        >
            {children}
        </EvmWalletContext.Provider>
    );
};
