import axios, { AxiosResponse } from 'axios';
import { watchdogUrl } from 'consts';
import { NetworkType } from 'types';

type IFetchPoolsVolumeResponse = any;

export const fetchPoolsVolume = async () => {
    return await axios
        .get<never, AxiosResponse<IFetchPoolsVolumeResponse>>(
            `${watchdogUrl}/public/pool/volume/aptos`,
        )
        .then((res) => res.data);
};

type IFetchPoolsApyResponse = any;

export const fetchPoolApy = async (payload: {
    toNetworkType: NetworkType;
    toChainId: number;
    tokenEnum: string;
}) => {
    return await axios
        .get<never, AxiosResponse<IFetchPoolsApyResponse>>(
            `${watchdogUrl}/public/pool/apy`,
            {
                params: payload,
            },
        )
        .then((res) => res.data);
};
