import { fetchPoolApy, fetchPoolsVolume } from 'api/pools';
import { useEffect, useState } from 'react';
import { NetworkType, Tokens } from 'types';

export const usePoolData = ({
    networkType,
    token,
}: {
    networkType: NetworkType;
    token: Tokens;
}) => {
    const [volumes, setVolumes] = useState<any>(null);
    const [apy, setApy] = useState<any>(null);

    useEffect(() => {
        if (networkType && token) {
            fetchPoolsVolume().then((response) => setVolumes(response));
            fetchPoolApy({
                toNetworkType: networkType,
                tokenEnum: token.toUpperCase(),
                toChainId: 2,
            }).then((response) => setApy(response.apy));
        }
    }, [networkType, token]);

    return { volumes, apy };
};
