import { cn } from '@bem-react/classname';
import { Icons } from 'assets';
import { FC } from 'react';

import './SpecialOfferModal.scss';
import { Button } from 'components';

const specialOfferImage = require('assets/images/coin.png');

const CnSpecialOfferModal = cn('specialOfferModal');

export const SpecialOfferModal: FC = () => {
    return (
        <div className={CnSpecialOfferModal()}>
            <div className={CnSpecialOfferModal('item')}>
                <div className={CnSpecialOfferModal('title')}>
                    Hold $MOVER
                    <br /> and save on fees!
                </div>

                <div className={CnSpecialOfferModal('image')}>
                    <div
                        className={CnSpecialOfferModal('image-background')}
                    ></div>
                    <img src={specialOfferImage} alt="COIN" />
                </div>
            </div>
            <div className={CnSpecialOfferModal('item')}>
                <div className={CnSpecialOfferModal('list')}>
                    <div className={CnSpecialOfferModal('list-item')}>
                        <div>1.</div> <div>Buy a token $MOVER</div>
                    </div>
                    <div className={CnSpecialOfferModal('list-item')}>
                        <div>2.</div>{' '}
                        <div>Keep $MOVER in your Aptos wallet</div>
                    </div>
                    <div className={CnSpecialOfferModal('list-item')}>
                        <div>3.</div>{' '}
                        <div>Save money on Bridge fee from Aptos</div>
                    </div>
                </div>
                <div className={CnSpecialOfferModal('condition')}>
                    <div className={CnSpecialOfferModal('condition-item')}>
                        Own 1000 $MOVER: save 50% on bridge fee
                    </div>
                    <div className={CnSpecialOfferModal('condition-item')}>
                        Own 5000 $MOVER: free bridging!
                    </div>
                </div>
                <div className={CnSpecialOfferModal('action')}>
                    <Button
                        onClick={() =>
                            window.open(
                                'https://www.bitget.com/spot/MOVERUSDT?type=spot',
                                '_blank',
                            )
                        }
                    >
                        Buy $MOVER
                    </Button>
                </div>
            </div>
        </div>
    );
};
