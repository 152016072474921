import axios from 'axios';
import { Currency } from 'types';
import { LocalStorage } from 'services';
import { coingeckoUrl } from 'consts';

const fetchBlockchainCoins = async (blockchain: string) =>
    await axios
        .get(`${coingeckoUrl}/coins/${blockchain}`, {
            params: {
                localization: false,
                tickers: false,
                market_data: true,
                community_data: false,
                developer_data: false,
                sparkline: false,
            },
        })
        .then((res) => res.data)
        .catch(() => null);

const getCoinPriceFromCache = (blockchain: string) => {
    const cache = LocalStorage.get('coingecko');

    if (!cache) return null;

    const parsedCache = JSON.parse(cache);

    if (!parsedCache) return null;

    const cachedPrice = parsedCache[blockchain];

    if (!cachedPrice) return null;

    return Number(cachedPrice);
};

const cacheCoinPrice = (blockchain: string, price: number) => {
    const cache = LocalStorage.get('coingecko');

    if (!cache) {
        LocalStorage.set('coingecko', JSON.stringify({ [blockchain]: price }));
        return;
    }

    const parsedCache = JSON.parse(cache);

    parsedCache[blockchain] = price;

    LocalStorage.set('coingecko', JSON.stringify(parsedCache));
};

export const fetchTokenPrice = async (
    currency: Currency,
    blockchain: string,
): Promise<number | null> => {
    const cache = LocalStorage.get('coingecko');
    const coins = await fetchBlockchainCoins(blockchain);

    if (!coins) {
        if (!cache) return null;

        return getCoinPriceFromCache(blockchain);
    }

    if (!coins.market_data.current_price.hasOwnProperty(currency)) return null;

    const price = coins.market_data.current_price[currency];

    cacheCoinPrice(blockchain, price);

    return price;
};
